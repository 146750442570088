import { useState } from "react";
import {
  getAuth,
  getTokenData,
  Hooks,
  setHeader,
  setTokenData,
  Types,
  Validator,
  toast,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Input } from "@hyperlocal/vital";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "../../services";
import * as Type from "../../services/types";
import * as Styles from "./styles";

const RequestToken = () => {
  const [searchParams] = useSearchParams();
  const { useIsomorphicLayoutEffect } = Hooks;
  const { checkPixKey } = Validator;

  const tokenData = getTokenData();
  const [input, setInput] = useState("");
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState<string>("");
  const [user, setUser] = useState<{
    profiles: Types["Profiles"];
    login: Types["ILogin"];
  }>(null);
  const [customKey, setCustomKey] = useState<{
    email?: string;
    phone_area?: string;
    phone_number?: string;
    phone?: string;
  }>();

  const navigate = useNavigate();

  const handleTimer = () => {
    return setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
  };

  const getUser = async () => {
    const { profiles, login } = await getAuth();
    setUser({ profiles, login });
  };
  const validateTokenPix = (guid: string): boolean => {
    if (
      guid === "948EB37E-5EFC-42C4-ADD5-256675D11FCC" ||
      guid === "39abb314-450d-4560-a240-f7c63f4cfb5e" ||
      guid === "09E8FC99-5B84-4ED4-B2E1-5EF4CC1D6469" ||
      guid === "92A1DF8F-26DC-4851-B096-89669ED99842" ||
      guid === "6423D801-4CA8-4BC8-8A22-C7DF386785D8" ||
      guid === "63D07325-4980-4ED9-9F6B-D7CFC985BA3F" ||
      guid === "BAE4CCC2-DE32-4D38-8942-58B0096E4EC7"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const getToken = async (body: Type.IToken) => {
    try {
      validateTokenPix(body.guid_token_type)
        ? await api.getToken(body)
        : await api.generateToken(body);
    } catch (error) {
      console.error("🚀 ~ file: index.tsx:72 ~ getToken ~ error:", error);
      toast.error(error?.response?.data);
    }
  };

  const setToken = (token: string | number) => {
    setTokenData({
      ...tokenData,
      response: {
        token,
      },
    });
  };

  const resendToken = async () => {
    setTimer(30);
    getToken({
      guid_user: user?.profiles?.guid_user ?? searchParams.get("q"),
      guid_token_type: tokenData?.request?.guid_token_type,
      ...customKey,
    });
  };

  const changeHeader = async (data: Types["IHeader"]) => {
    setHeader(data);
  };
  const overshadowPhone = (fone: string) => {
    return `•• ••••• ••${fone.substring(7)}`;
  };

  const handleCloseDrawer = () => {
    dispatchEvent(new CustomEvent("@hyperlocal-token-validation/close-drawer"));
  };

  const validateToken = async () => {
    try {
      const request: Type.IValidateToken = {
        token: input,
        guid_token_type: tokenData?.request?.guid_token_type,
        guid_user: tokenData?.request.guid_user,
        ...customKey,
      };
      validateTokenPix(tokenData?.request?.guid_token_type)
        ? await api.validateToken(request)
        : await api.TokenValidate(request);
      setToken(input);
      setError("");

      navigate(tokenData.request.path);
      handleCloseDrawer();
    } catch (error) {
      console.error("🚀 ~ file: index.tsx:125 ~ validateToken ~ error:", error)
      if (error?.response?.data === "Token inválido!") {
        return setError("Token inválido!");
      }
      const errorText = error?.response?.data?.errors[0]?.friendlyMessage ?? "";
      setError(errorText);
      toast.error(errorText);
    }
  };
  useIsomorphicLayoutEffect(() => {
    changeHeader(tokenData?.request?.header);
    getUser();
    handleTimer();
    if (checkPixKey(tokenData?.request?.customKey ?? "") === "Celular") {
      setCustomKey({
        phone: tokenData?.request?.customKey,
        phone_area: tokenData?.request?.customKey
          .replace(/\D/g, "")
          .substring(0, 2),
        phone_number: tokenData?.request?.customKey
          .replace(/\D/g, "")
          .substring(2, tokenData?.request?.customKey.length),
      });
    } else if (checkPixKey(tokenData?.request?.customKey ?? "") === "E-mail") {
      setCustomKey({
        email: tokenData?.request?.customKey,
      });
    }
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (timer) handleTimer();
  }, [timer]);
  useIsomorphicLayoutEffect(() => {
    if (
      tokenData?.request?.customKey &&
      (customKey?.email || customKey?.phone_number)
    )
      getToken({
        guid_user: tokenData?.request.guid_user,
        guid_token_type: tokenData?.request?.guid_token_type,
        ...customKey,
      });
  }, [customKey]);
  // 438e391e-4e3e-4f25-8779-26111e9174a7
  useIsomorphicLayoutEffect(() => {
    if (!tokenData?.request?.customKey)
      getToken({
        guid_user: tokenData?.request?.guid_user,
        guid_token_type: tokenData?.request?.guid_token_type,
        ...customKey,
      });
  }, [tokenData?.request]);

  return (
    <Styles.PageWrapper>
      <div>
        <h4>
          {checkPixKey(
            customKey?.email ??
              `${customKey?.phone_area}${customKey?.phone_number}` ??
              "",
          ) === "E-mail"
            ? "Digite o código do Token que foi enviado via e-mail"
            : checkPixKey(
                customKey?.email ??
                  `${customKey?.phone_area}${customKey?.phone_number}` ??
                  "",
              ) === "Celular"
            ? "Digite o código do Token que foi enviado via Celular"
            : "Digite o código do Token que foi enviado via SMS para"}
        </h4>
        <h5>
          {customKey?.email || customKey?.phone_number ? (
            <>
              {customKey?.email ??
                `(${customKey?.phone_area}) ${customKey?.phone_number}`}
            </>
          ) : (
            user?.login &&
            `55 ${
              user?.login?.cellphone && overshadowPhone(user?.login?.cellphone)
            }`
          )}
        </h5>
        <Input
          type="token"
          label="Código de 4 caracteres"
          onChangeValue={(e) => setInput(e)}
          helper={error ? "error" : undefined}
          helperText={error}
        />
        <span>Não recebeu o Token?</span>
        {!timer ? (
          <Button variant="link" onClick={resendToken}>
            Reenviar código do Token
          </Button>
        ) : (
          <p>Voce poderá solicitar um novo Token em {timer} segundos</p>
        )}
      </div>
      <Button disabled={!(input.length > 3)} onClick={validateToken}>
        Validar Token
      </Button>
    </Styles.PageWrapper>
  );
};
export default RequestToken;
