// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { API } from "@hyperlocal/banking-utility";
import * as Types from "./types";

const api = {
  getToken: async (data: Types.IToken) =>
    await API.token.post<string>(`api/v1/Token/CreateCustom`, data),
  generateToken: async (data: Types.IToken) =>
    await API.manager.post<string>(`Desk/GenerateToken`, data),
  TokenValidate: async (data: Types.IValidateToken) =>
    await API.manager.get<{ message: string }>(
      `/Desk/CheckToken?guid_token_type=${data.guid_token_type}&guid_user=${data.guid_user}&token=${data.token}`,
    ),
  validateToken: async (data: Types.IValidateToken) =>
    await API.token.get<{ message: string }>(
      `api/v1/Token/Check?guid_token_type=${data.guid_token_type}&token=${
        data.token
      }&guid_user=${data.guid_user}${
        data?.phone ? `&phone=${data?.phone}` : ""
      }${data?.email ? `&email=${data?.email}` : ""}`,
    ),
};

export default api;
