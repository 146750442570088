import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import RequestToken from "../components/GetToken";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/*" element={<RequestToken />} />
    </Switch>
  );
};
