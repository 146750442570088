// @ts-ignore
import { Notifications } from "@hyperlocal/banking-utility";
import { avecTheme, Provider } from "@hyperlocal/vital";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { Routes } from "./Routes";

const App = () => {
  return (
    <Provider theme="avec">
      <ThemeProvider theme={avecTheme}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
        <Notifications />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
