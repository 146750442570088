import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
  
  body {
    font-family: 'Inter', sans-serif;
  }

  main{
    min-height: 100vh;
    & >div{
      min-height: calc(100vh - 56px);
    }
  }
`;

export const WrapperDrawer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 50px;
  & > h4 {
    margin-top: 23px;
    margin-bottom: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
  }
  & > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    & > svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${(props) => props.theme.palette.neutral.darker};
      }
    }
  }
`;
export const PageWrapper = styled.section`
  ${({ theme }) =>
    theme.breakpoints.mobile(`
      padding: 24px 16px;
      height: calc(100vh - 56px);
  `)}

  ${({ theme }) =>
    theme.breakpoints.desktop(`
      padding-top: 24px;
      height: calc(100vh - 72px);
    `)}

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & > * {
      font-family: "Inter";
      font-style: normal;
      text-align: center;
    }
    & > h4 {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      display: flex;
      align-items: center;
      text-align: center;
      width: 310px;
      margin-bottom: 16px;
      color: ${(props) => props.theme.palette.neutral.darkest};
    }
    & > h5 {
      color: ${(props) => props.theme.palette.primary.main};
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 24px;
      line-height: 150%;
    }
    & > div {
      margin-bottom: 34px;
      & > div {
        justify-content: center;

        label {
          justify-content: center;
        }
      }
    }
    & > span {
      margin-bottom: 16px;
      font-weight: 500;
      font-size: 12px;
      color: ${(props) => props.theme.palette.neutral.dark};
      line-height: 150%;
    }
    & > p {
      font-size: 14px;
      color: ${(props) => props.theme.palette.neutral.dark};
      width: 284px;
      line-height: 125%;
    }
  }
  & > button {
    justify-content: center;
    width: 100%;
  }
`;
